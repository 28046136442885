<template>
  <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center">
    <div class="bg-white w-fullrounded-lg shadow">
      <div class="h-20 py-3 flex items-center border-gray-200 p-6 mt-5">
        <div class="flex">
          <router-link :class="$colors.primary" class="rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1"
            to="/products">
            <i class="material-icons mx-2">chevron_left</i>
          </router-link>
          <div v-if="!formData._id" class="text-2xl font-bold text-green-500">
            {{ $t("new_product") }}
          </div>
          <div v-else class="text-2xl font-bold text-green-500">
            {{ $t("edit_product") }}
          </div>
        </div>
      </div>

      <div class="">
        <div class="bg-white rounded-lg p-6 relative">
          <loading :active.sync="loading" :is-full-page="fullPage"></loading>
          <div class="grid lg:grid-cols-2 gap-6">
            <div
              class="border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="name" class="bg-white text-gray-700 px-1">{{ $t("name") }}
                    <span :class="$colors.required">*</span></label>
                </p>
              </div>
              <p>
                <input id="name" autocomplete="false" tabindex="0" type="text" v-model="formData.name"
                  class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>

            <div
              class="border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="description" class="bg-white text-gray-700 px-1">
                    {{ $t("description") }}
                    <span :class="$colors.required">*</span>
                  </label>
                </p>
              </div>
              <p>
                <input id="description" autocomplete="false" tabindex="0" type="description"
                  v-model="formData.description" class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>


             <div
              class="border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="price" class="bg-white text-gray-700 px-1">
                    {{ $t("price") }}
                    <span :class="$colors.required">*</span>
                  </label>
                </p>
              </div>
              <p>
                <input id="price" autocomplete="false" tabindex="0" type="number"
                  v-model="formData.price" class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>

 


            <div
              class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="code" class="bg-white text-gray-700 px-1">{{ $t("code") }}
                  </label>
                </p>
              </div>
              <p>
                <input id="code" autocomplete="false" tabindex="0" type="text" v-model="formData.code"
                  class="py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full" />
              </p>
            </div>

            <!-- <div  v-if="formData._id"
              class="border hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="price" class="bg-white text-gray-700 px-1"
                    >{{$t('price')}} </label
                  >
                </p>
              </div>
              <div v-for="(item, key) in formData.details" :key="key"  class="border hover:border-green-500  hover:border-green-500  focus-within:border-blue-500 flex flex-wrap items-stretch w-full mb-2 relative h-15 bg-white items-center rounded">
                <div class="flex -mr-px justify-center p-1">
                  <span class="flex border border-black items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-sm font-bold text-gray-600">
                    <sup> {{ item.warehouse?item.warehouse.currency:currency}} </sup><span class="select-none ml-2"> <img v-if="countryW" class="flag-img" :src="$f.getCountryCode(item.warehouse?item.warehouse.country:countryW)"/></span>
                  </span>
                </div>
                <input type="number"  v-model="item.price" min="0" class="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded rounded-l-none px-3 self-center relative outline-none">
              </div>
            </div>-->

            <span class="p-float-label card p-fluid w-full">
              <Chips id="tags" v-model="formData.tags" />
              <label for="value">Tags</label>
            </span>

            <div
              class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="verificationLink" class="bg-white text-gray-700 px-1">
                    {{ $t("verification_link") }}
                    <span :class="$colors.required">*</span>
                  </label>
                </p>
              </div>
              <p>
                <input id="verificationLink" autocomplete="false" tabindex="0" type="text"
                  v-model="formData.verificationLink" class="py-2 text-xs px-2 outline-none block h-full w-full" />
              </p>
            </div>

            <div v-if="
              formData._id &&
              (currentUser.type == 'Admin' ||
                currentUser.type == 'CEO' ||
                currentUser.type == 'StockManager')
            "
              class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolutes tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="outOfStock" class="bg-white text-gray-700 px-1">{{ $t("out_of_stock") }}
                  </label>
                </p>
              </div>
              <p v-for="(item, key) in formData.details" :key="key"
                class="flex items-center justify-center w-full py-2">
                <!-- Toggle Button -->
                <label :for="'toogle' + key" class="flex items-center cursor-pointer">
                  <div class="select-none mr-5">
                    <img class="flag-img" :src="$f.getCountryCode(item.warehouse.country)" />
                    {{ item.warehouse.name }}
                  </div>
                  <div class="relative">
                    <input :id="'toogle' + key" type="checkbox" class="hidden" v-model="item.outOfStock" />
                    <div class="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                    <div :class="'toggle__dot-' + key"
                      class="absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                  </div>
                  <div class="ml-3 text-gray-700 capitalize">
                    {{ $t(item.outOfStock) }}
                  </div>
                </label>
              </p>
            </div>
            <div v-if="
              currentUser &&
              currentUser.type &&
              currentUser.type != 'Seller' &&
              all['multi-sellers'].isActive
            "
              class="border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="seller" class="bg-white text-gray-700 px-1">
                    {{ $t("seller") }}
                    <span :class="$colors.required">*</span></label>
                </p>
              </div>
              <p>
                <v-select label="fullName" @search="searchSellers($event, 'hh')" v-model="formData.seller"
                  :options="sellers"></v-select>
              </p>
            </div>

            <!--div
              class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="category" class="bg-white text-gray-700 px-1"
                    >Category
                  </label>
                </p>
              </div>
              <p>
                <select
                  id="type"
                  name="category"
                  autocomplete="type"
                  v-model="formData.category"
                  class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                >
                  <option selected disabled :value="null">Type</option>
                  <option
                    v-for="category in categories"
                    :key="category._id"
                    :value="category._id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </p>
            </div-->

            <div
              class="border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1">
              <div class="-mt-4 absolute tracking-wider px-1 capitalize text-xs">
                <p>
                  <label for="Picture" class="bg-white text-gray-700 px-1">
                    {{ $t("picture") }}
                    <span :class="$colors.required">*</span></label>
                </p>
              </div>
              <!--<p>
                <input id="Picture" autocomplete="false" tabindex="0" type="text" v-model="formData.picture" class="py-2 text-xs px-2 outline-none block h-full w-full"/>
              </p>-->
              <div class="flex w-full mt-10 mb-10 items-center justify-center bg-grey-lighter">
                <label
                  class="flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white">
                  <svg v-if="formData.picture == ''" class="w-8 h-8" fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                  </svg>
                  <img class="w-64 h-64" v-if="formData.picture != ''" :src="formData.picture" />
                  <span v-if="formData.picture == ''" class="mt-2 text-base leading-normal">
                    {{ $t("select_image") }}</span>
                  <input type="file" @change="handleImage($event)" class="hidden" accept="image/*" />

                  <span v-if="formData._id && !formData.picture"
                    class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
                    <p>La taille de image doit pas dépasser 500 ko</p>
                  </span>
                </label>
              </div>
            </div>

            <!--div
              class="border h-10 hover:border-green-500  focus-within:border-blue-500 focus-within:text-green-500  transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="parentProduct" class="bg-white text-gray-700 px-1"
                    >Parent product
                  </label>
                </p>
              </div>
              <p>
                <select
                  id="type"
                  name="parentProduct"
                  autocomplete="type"
                  v-model="formData.parentproduct"
                  class="py-2 text-xs capitalize px-2 outline-none block h-full w-full bg-white"
                >
                  <option selected disabled :value="null">
                    Parent product
                  </option>
                  <option
                    v-for="product in products"
                    :key="product._id"
                    :value="product._id"
                  >
                    {{ product.name }}
                  </option>
                </select>
              </p>
            </div-->
          </div>

          <div class="mt-6 pt-3 flex justify-center">
            <button
              class="rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
              @click="save">
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chips from "primevue/chips";
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "ProductForm",
  components: {
    Chips,
  },
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      formData: {
        name: "",
        code: "",
        price: 0,
        description: "",
        quantity: {
          inStock: 0,
          total: 0,
        },
        tags: null,
        details: [{ price: 0 }],
        verificationLink: "",
        outOfStock: false,
        picture: "",
        seller: "",
        prices: [{ price: 0, warehouse: null }],
      },
      loading: false,
      fullPage: false,
      //currentUser: {},
      sellers: [],
      categories: [],
      products: [],
      sellersExist: false,
      //idWharhouse:null,
      currency: "",
      countryW: "",
    };
  },

  async mounted() {
    // await this.getUser();
    // await this.getwharhouseId();
    await this.getContryW();
    this.dataToEdit();
    // this.getCategories();

    if (
      this.currentUser &&
      this.currentUser.type &&
      this.currentUser.type != "Seller"
    )
      await this.getSellers();
    else this.formData.seller = this.currentUser._id;

    this.getProducts();
  },
  computed: {
    ...mapState({
      all: (state) => state.features.all,
      defaultSeller: (state) => state.app.defaultSeller,
      defaultWarehouse: (state) => state.app.defaultWarehouse,
    }),
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      //await this.getwharhouseId();
    },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      await this.getContryW();
      if (
        this.currentUser &&
        this.currentUser.type &&
        this.currentUser.type != "Seller"
      )
        await this.getSellers();
      else this.formData.seller = this.currentUser._id;
    },
  },
  methods: {
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async getContryW() {
      const warhouse = await this.$server.get("warehouses", {
        id: this.idWharhouse._id,
      });
      this.countryW = warhouse.content.country;
    },
    async getCurrency() {
      await this.$store.dispatch("wharhouse/getCurrency").then((res) => {
        this.currency = res;
      });
    },
    //  async searchSellers(value,event) {
    //    //alert('ooooooooook',"warning")

    //      const filter = {
    //       type: "Seller",
    //       limi:this.limit,
    //       name: value,
    //     };
    //     const res= await this.$server.find("users",filter);

    //    if (res.content.results) this.sellers = res.content.results;
    //    else this.sellers = [];
    //   },
    searchSellers: _.debounce(async function (search) {
      const filter = {
        type: "Seller",
        limit: this.limit,
        countries: await this.warhouseSelected,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    }, 500),
    handleImage(e) {
      const selectImage = e.target.files[0];
      if (selectImage) this.createBase64Image(selectImage);
    },
    createBase64Image(fileObjet) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObjet);
      reader.onload = () => {
        this.formData.picture = reader.result;
      };
    },
    async save() {
      if (this.formData._id) {
        if (!this.checkSize()) {
          alert(`la taille de image doit pas dépasser 500 ko !! `, "warning");
          return false;
        }
        this.loading = true;
        const data = await this.$server.update("products", this.formData);
        this.loading = false;
        if (data && data._id) {
          alert(this.$t("product_updated"), "success");
        } else {
          if (data.content) alert(data.content, "error");
          else
            alert(
              "There was a problem updating the product, please try again later",
              "error"
            );
        }
      } else {
        if (this.checkData() && this.checkSize()) {
          this.loading = true;
          //this.formData.quantity.total = this.formData.quantity.inStock;
          // this.formData.details[0].warehouse = this.idWharhouse._id;
          // add warehouse to product
          this.formData.details[0].warehouse =
            this.defaultWarehouse && !this.all["multi-warehouses"].isActive
              ? this.defaultWarehouse
              : this.idWharhouse._id;


          // add seller to product
          this.formData.seller =
            this.defaultSeller && !this.all["multi-sellers"].isActive
              ? this.sellers.find((s) => s._id == this.defaultSeller)
              : this.formData.seller;

          const data = await this.$server.create("products", this.formData);
          this.loading = false;

          if (data && data._id) {
            this.formData = {
              name: "",
              code: "",
              price: 0,
              description: "",
              quantity: {
                inStock: 0,
                total: 0,
              },
              details: [{ price: 0 }],
              verificationLink: "",
              outOfStock: false,
              picture: "",
              seller: "",
            };
            if (this.currentUser.type == "Seller") {
              this.formData.seller = this.currentUser._id;
            }
            alert(this.$t("product_created"), "success");
          } else {
            if (data.content) alert(data.content, "error");
            else
              alert(
                "There was a problem creating the product, please try again later",
                "error"
              );
          }
        } else {
          if (!this.checkData())
            alert(this.$t("all_required_field"), "warning");
          if (!this.checkSize())
            alert(`la taille de image doit pas dépasser 500 ko !! `, "warning");
        }
      }
    },
    checkData() {
      if (
        this.formData.name &&
        this.formData.description &&
        this.formData.verificationLink &&
        this.formData.picture
      )
        return true;
      else return false;
    },
    niceBytes(x) {
      const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    },
    checkSize() {
      let base64Length =
        this.formData.picture.length - (this.formData.picture.indexOf(",") + 1);
      let padding =
        this.formData.picture.charAt(this.formData.picture.length - 2) === "="
          ? 2
          : this.formData.picture.charAt(this.formData.picture.length - 1) ===
            "="
            ? 1
            : 0;
      let fileSize = base64Length * 0.75 - padding;
      let sizeImage = this.niceBytes(fileSize);
      let typeSize = sizeImage.split(" ")[1];
      if (typeSize === "bytes" || typeSize === "KB") {
        if (sizeImage.split(" ")[0] <= 500) return true;
        else return false;
      } else return false;
    },
    /**************************/
    async dataToEdit() {
      if (this.$route.params.id) {
        this.loading = true;
        const editData = await this.$server.get("products", {
          id: this.$route.params.id,
        });
        this.formData = editData.content;
        this.loading = false;
      }
    },
    async getCategories() {
      const res = await this.$server.search("categories");
      if (res.content.results) this.categories = res.content.results;
      else this.categories = [];
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        countries: await this.warhouseSelected,
      };
      const res = await this.$server.search("users", filter);
      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
    },
    async getProducts() {
      const res = await this.$server.search("products");
      if (res.content.results) this.products = res.content.results;
      else this.products = [];
    },
    async getUser() {
      const res = await this.$server.me("users");
      if (res.content) this.currentUser = res.content;
      else this.currentUser = {};
      //
    },
  },
};
</script>

<style>
[class*="toggle__dot-"] {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked~[class*="toggle__dot-"] {
  transform: translateX(100%);
  background-color: #63b3ed;
}

.vs__dropdown-toggle {
  border: none;
}
</style>
