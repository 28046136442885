var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 pt-12 shadow-lg items-center"
  }, [_c('div', {
    staticClass: "bg-white w-fullrounded-lg shadow"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('router-link', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3 mt-1",
    class: _vm.$colors.primary,
    attrs: {
      "to": "/products"
    }
  }, [_c('i', {
    staticClass: "material-icons mx-2"
  }, [_vm._v("chevron_left")])]), !_vm.formData._id ? _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("new_product")) + " ")]) : _c('div', {
    staticClass: "text-2xl font-bold text-green-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("edit_product")) + " ")])], 1)]), _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6 relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "name"
    }
  }, [_vm._v(_vm._s(_vm.$t("name")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.name,
      expression: "formData.name"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "name",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "description"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("description")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.description,
      expression: "formData.description"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "description",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "description"
    },
    domProps: {
      "value": _vm.formData.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "description", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "price"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("price")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.price,
      expression: "formData.price"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "price",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "number"
    },
    domProps: {
      "value": _vm.formData.price
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "price", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "code"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")) + " ")])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.code,
      expression: "formData.code"
    }],
    staticClass: "py-2 text-xs px-2 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "code",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "code", $event.target.value);
      }
    }
  })])]), _c('span', {
    staticClass: "p-float-label card p-fluid w-full"
  }, [_c('Chips', {
    attrs: {
      "id": "tags"
    },
    model: {
      value: _vm.formData.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "tags", $$v);
      },
      expression: "formData.tags"
    }
  }), _c('label', {
    attrs: {
      "for": "value"
    }
  }, [_vm._v("Tags")])], 1), _c('div', {
    staticClass: "border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "verificationLink"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("verification_link")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.verificationLink,
      expression: "formData.verificationLink"
    }],
    staticClass: "py-2 text-xs px-2 outline-none block h-full w-full",
    attrs: {
      "id": "verificationLink",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.verificationLink
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "verificationLink", $event.target.value);
      }
    }
  })])]), _vm.formData._id && (_vm.currentUser.type == 'Admin' || _vm.currentUser.type == 'CEO' || _vm.currentUser.type == 'StockManager') ? _c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolutes tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "outOfStock"
    }
  }, [_vm._v(_vm._s(_vm.$t("out_of_stock")) + " ")])])]), _vm._l(_vm.formData.details, function (item, key) {
    return _c('p', {
      key: key,
      staticClass: "flex items-center justify-center w-full py-2"
    }, [_c('label', {
      staticClass: "flex items-center cursor-pointer",
      attrs: {
        "for": 'toogle' + key
      }
    }, [_c('div', {
      staticClass: "select-none mr-5"
    }, [_c('img', {
      staticClass: "flag-img",
      attrs: {
        "src": _vm.$f.getCountryCode(item.warehouse.country)
      }
    }), _vm._v(" " + _vm._s(item.warehouse.name) + " ")]), _c('div', {
      staticClass: "relative"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: item.outOfStock,
        expression: "item.outOfStock"
      }],
      staticClass: "hidden",
      attrs: {
        "id": 'toogle' + key,
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(item.outOfStock) ? _vm._i(item.outOfStock, null) > -1 : item.outOfStock
      },
      on: {
        "change": function change($event) {
          var $$a = item.outOfStock,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = null,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && _vm.$set(item, "outOfStock", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(item, "outOfStock", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(item, "outOfStock", $$c);
          }
        }
      }
    }), _c('div', {
      staticClass: "toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"
    }), _c('div', {
      staticClass: "absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0",
      class: 'toggle__dot-' + key
    })]), _c('div', {
      staticClass: "ml-3 text-gray-700 capitalize"
    }, [_vm._v(" " + _vm._s(_vm.$t(item.outOfStock)) + " ")])])]);
  })], 2) : _vm._e(), _vm.currentUser && _vm.currentUser.type && _vm.currentUser.type != 'Seller' && _vm.all['multi-sellers'].isActive ? _c('div', {
    staticClass: "border seller-select h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "seller"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("seller")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('p', [_c('v-select', {
    attrs: {
      "label": "fullName",
      "options": _vm.sellers
    },
    on: {
      "search": function search($event) {
        return _vm.searchSellers($event, 'hh');
      }
    },
    model: {
      value: _vm.formData.seller,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "seller", $$v);
      },
      expression: "formData.seller"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "border hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "Picture"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("picture")) + " "), _c('span', {
    class: _vm.$colors.required
  }, [_vm._v("*")])])])]), _c('div', {
    staticClass: "flex w-full mt-10 mb-10 items-center justify-center bg-grey-lighter"
  }, [_c('label', {
    staticClass: "flex flex-col items-center px-4 py-6 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-white"
  }, [_vm.formData.picture == '' ? _c('svg', {
    staticClass: "w-8 h-8",
    attrs: {
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  }, [_c('path', {
    attrs: {
      "d": "M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
    }
  })]) : _vm._e(), _vm.formData.picture != '' ? _c('img', {
    staticClass: "w-64 h-64",
    attrs: {
      "src": _vm.formData.picture
    }
  }) : _vm._e(), _vm.formData.picture == '' ? _c('span', {
    staticClass: "mt-2 text-base leading-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("select_image")))]) : _vm._e(), _c('input', {
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.handleImage($event);
      }
    }
  }), _vm.formData._id && !_vm.formData.picture ? _c('span', {
    staticClass: "bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', [_vm._v("La taille de image doit pas dépasser 500 ko")])]) : _vm._e()])])])]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center"
  }, [_c('button', {
    staticClass: "rounded focus:outline-none outline-none text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")])])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }